import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout/Layout';

export default (): JSX.Element => {
    return (
        <div></div>
    )
    
}

// export const query = graphql`
//     query {
//         allCity {
//             edges {
//                 node {
//                     annotations {
//                         geohash
//                     }
//                     formatted
//                     geometry {
//                         lat
//                         lng
//                     }
//                 }
//             }
//         }
//     }
// `
